import lodash from 'lodash';
import {runInAction} from 'mobx';

import {actionPositionComponent} from '../components/action/actionPositionComponent';
import {
  adjustForAlignment,
  getBoundaries,
  getEntityPosition,
  getEntityPositionAlignment,
  getEntitySize
} from '../ecs/entityHelper';
import * as option from '../../constants/entityOptionConstants';
import {ALIGN_FAR, ALIGN_CENTERED} from '../../constants/entityConstants';

/**
 * The name of the system.
 * @const {string}
 */
export const ALIGNMENT_SYSTEM = 'alignmentSystem';

/**
 * Gets a new instance of the alignment system.
 *
 * @param {GameStore} game
 * @returns {{name: string, runActions: systemRunActions}}
 */
export function alignmentSystem(game) {
  /**
   * Called right before the game loop updates.
   *
   * @param {Array.<{}>} actions
   * @param {Array.<{}>} entities
   */
  function systemRunActions(actions, entities) {
    runInAction('alignmentSystemUpdateEntity', () => {
      actions.forEach((actionEntity) => {
        if (!actionEntity.has('actionAlign')) {
          return;
        }

        const action = actionEntity.get('action');
        const activeEntityIds = action.entityId;

        if (!activeEntityIds || typeof activeEntityIds === 'string') {
          return;
        } else if (!activeEntityIds.length) {
          return;
        }

        const activeEntities = lodash.intersectionBy(entities, activeEntityIds, (item) => {
          if (lodash.isString(item)) {
            return item;
          }
          return item.get('id');
        });

        if (!activeEntities) {
          return;
        }

        const actionAlign = actionEntity.get('actionAlign');
        const alignment = actionAlign.alignment;
        const resolution = game.resolution;

        const positions = getNewPositions(activeEntities, alignment, resolution);
        if (!positions) {
          return;
        }

        positions.forEach((item) => {
          game.addAction(
            {entityId: item.entity.get('id')},
            actionPositionComponent(false, item.positionDelta, item.sizeDelta || null, null, true)
          );
        });
      });
    });
  }

  /**
   * Gets the new positions for the entities.
   *
   * @param {object[]} activeEntities
   * @param {string} alignment
   * @param {{height: number, width: number}} resolution
   * @returns {?Array.<{entity: {}, positionDelta: {}, sizeDelta: {}}>}
   */
  function getNewPositions(activeEntities, alignment, resolution) {
    if (lodash.includes([option.ALIGN_LEFT, option.ALIGN_TOP], alignment)) {
      return alignLeftOrTop(activeEntities, alignment, resolution);
    }
    if (lodash.includes([option.ALIGN_RIGHT, option.ALIGN_BOTTOM], alignment)) {
      return alignRightOrBottom(activeEntities, alignment, resolution);
    }
    if (lodash.includes([option.ALIGN_CENTER, option.ALIGN_MIDDLE], alignment)) {
      return alignCenterOrMiddle(activeEntities, alignment, resolution);
    }
    if (lodash.includes([option.CENTER_HORIZONTALLY, option.CENTER_VERTICALLY], alignment)) {
      return centerItemsOnPage(activeEntities, alignment, resolution);
    }
    if (lodash.includes([option.DISTRIBUTE_HORIZONTALLY, option.DISTRIBUTE_VERTICALLY], alignment)) {
      return distributeItems(activeEntities, alignment, resolution);
    }
    if (lodash.includes([option.FIT_FULL, option.FIT_CENTER_HALF], alignment)) {
      return expandToFit(activeEntities, alignment, resolution);
    }

    return null;
  }

  /**
   * Gets all the position values for the active entities.
   *
   * @param {object[]} activeEntities
   * @param {string} positionProp
   * @returns {number[]}
   */
  function getAllValues(activeEntities, positionProp) {
    if (activeEntities.length < 2) {
      // If only one entity, align to the extreme.
      return [0];
    }

    return activeEntities.map((entity) => getEntityPosition(entity)[positionProp]);
  }

  /**
   * Aligns the items to the far left or far top.
   *
   * @param {object[]} activeEntities
   * @param {string} alignment
   * @param {{width: number, height: number}} resolution
   * @returns {Array.<{entity: {}, positionDelta: {}}>}
   */
  function alignLeftOrTop(activeEntities, alignment, resolution) {
    const positionProp = (option.ALIGN_LEFT === alignment) ? 'x' : 'y';
    const sizeProp = (option.ALIGN_LEFT === alignment) ? 'width' : 'height';

    let farStart;
    if (activeEntities.length >= 2) {
      farStart = getBoundaries(activeEntities, positionProp, sizeProp, resolution).start[positionProp];
    } else {
      farStart = 0;
    }

    return activeEntities.map((entity) => {
      const position = getEntityPosition(entity);
      const size = getEntitySize(entity);
      const entityAlignment = getEntityPositionAlignment(entity, positionProp);

      const newValue = adjustForAlignment(
        entityAlignment,
        farStart,
        size[sizeProp],
        resolution[sizeProp],
        true
      );

      let positionDelta;
      if (entityAlignment === ALIGN_CENTERED) {
        positionDelta = newValue - position[positionProp];
      } else if (entityAlignment === ALIGN_FAR) {
        if (activeEntities.length >= 2) {
          positionDelta = position[positionProp] - newValue;
        } else {
          positionDelta = position[positionProp] - newValue;
        }
      } else {
        positionDelta = newValue - position[positionProp];
      }

      return {
        entity,
        positionDelta: {
          [positionProp]: positionDelta,
        },
      };
    });
  }

  /**
   * Aligns the items to the far right or far bottom.
   *
   * @param {object[]} activeEntities
   * @param {string} alignment
   * @param {{width: number, height: number}} resolution
   * @returns {Array.<{entity: {}, positionDelta: {}}>}
   */
  function alignRightOrBottom(activeEntities, alignment, resolution) {
    const positionProp = (option.ALIGN_RIGHT === alignment) ? 'x' : 'y';
    const sizeProp = (option.ALIGN_RIGHT === alignment) ? 'width' : 'height';
    const allValues = getAllValues(activeEntities, positionProp);

    let end = null;
    if (activeEntities.length >= 2) {
      end = getBoundaries(activeEntities, positionProp, sizeProp, resolution).end;
    } else {
      end = {[positionProp]: 0 - lodash.max(allValues), [sizeProp]: resolution[sizeProp]};
    }
    const farEnd = end[positionProp] + end[sizeProp];

    return activeEntities.map((entity) => {
      const position = getEntityPosition(entity);
      const size = getEntitySize(entity);
      const entityAlignment = getEntityPositionAlignment(entity, positionProp);

      const newValue = adjustForAlignment(
        entityAlignment,
        farEnd - size[sizeProp],
        size[sizeProp],
        resolution[sizeProp],
        true
      );

      let positionDelta;
      if (entityAlignment === ALIGN_CENTERED) {
        positionDelta = newValue - position[positionProp];
      } else if (entityAlignment === ALIGN_FAR) {
        if (activeEntities.length >= 2) {
          positionDelta = 0 - newValue + position[positionProp];
        } else {
          positionDelta = newValue + position[positionProp];
        }
      } else {
        positionDelta = newValue - position[positionProp];
      }

      return {
        entity,
        positionDelta: {
          [positionProp]: positionDelta,
        },
      };
    });
  }

  /**
   * Aligns the items to the center or middle.
   *
   * @param {object[]} activeEntities
   * @param {string} alignment
   * @param {{width: number, height: number}} resolution
   * @returns {Array.<{entity: {}, positionDelta: {}}>}
   */
  function alignCenterOrMiddle(activeEntities, alignment, resolution) {
    const positionProp = (option.ALIGN_CENTER === alignment) ? 'x' : 'y';
    const sizeProp = (option.ALIGN_CENTER === alignment) ? 'width' : 'height';
    const allValues = getAllValues(activeEntities, positionProp);

    let start = null;
    let end = null;
    if (activeEntities.length >= 2) {
      const boundaryItems = getBoundaries(activeEntities, positionProp, sizeProp, resolution);
      start = boundaryItems.start;
      end = boundaryItems.end;
    } else {
      start = {x: 0, y: 0};
      end = {[positionProp]: 0 - lodash.max(allValues), [sizeProp]: resolution[sizeProp]};
    }

    const farStart = start[positionProp];
    const farEnd = end[positionProp] + end[sizeProp];

    const newCenter = ((farEnd - farStart) / 2) + farStart;

    return activeEntities.map((entity) => {
      const position = getEntityPosition(entity);
      const size = getEntitySize(entity);
      const entityAlignment = getEntityPositionAlignment(entity, positionProp);

      const newValue = adjustForAlignment(
        entityAlignment,
        newCenter - (size[sizeProp] / 2),
        size[sizeProp],
        resolution[sizeProp],
        true
      );

      let positionDelta;
      if (entityAlignment === ALIGN_CENTERED) {
        positionDelta = newValue - position[positionProp];
      } else if (entityAlignment === ALIGN_FAR) {
        positionDelta = position[positionProp] - newValue;
      } else {
        positionDelta = newValue - position[positionProp];
      }

      return {
        entity,
        positionDelta: {
          [positionProp]: positionDelta,
        },
      };
    });
  }

  /**
   * Centers the items on the page either horizontally or vertically.
   *
   * @param {object[]} activeEntities
   * @param {string} alignment
   * @param {{width: number, height: number}} resolution
   * @returns {Array.<{entity: {}, positionDelta: {}}>}
   */
  function centerItemsOnPage(activeEntities, alignment, resolution) {
    const positionProp = (option.CENTER_HORIZONTALLY === alignment) ? 'x' : 'y';
    const sizeProp = (option.CENTER_HORIZONTALLY === alignment) ? 'width' : 'height';

    const newCenter = (resolution[sizeProp] / 2);

    return activeEntities.map((entity) => {
      const position = getEntityPosition(entity);
      const size = getEntitySize(entity);
      const entityAlignment = getEntityPositionAlignment(entity, positionProp);

      const newValue = adjustForAlignment(
        entityAlignment,
        newCenter - (size[sizeProp] / 2),
        size[sizeProp],
        resolution[sizeProp],
        true
      );

      let positionDelta;
      if (entityAlignment === ALIGN_CENTERED) {
        positionDelta = newValue - position[positionProp];
      } else if (entityAlignment === ALIGN_FAR) {
        positionDelta = position[positionProp] - newValue;
      } else {
        positionDelta = newValue - position[positionProp];
      }

      return {
        entity,
        positionDelta: {
          [positionProp]: positionDelta,
        },
      };
    });
  }

  /**
   * Distributes the items evenly either horizontally or vertically.
   *
   * @param {object[]} activeEntities
   * @param {string} alignment
   * @param {{height: number, width: number}} resolution
   * @returns {Array.<{entity: {}, positionDelta: {}}>}
   */
  function distributeItems(activeEntities, alignment, resolution) {
    const positionProp = (option.DISTRIBUTE_HORIZONTALLY === alignment) ? 'x' : 'y';
    const sizeProp = (option.DISTRIBUTE_HORIZONTALLY === alignment) ? 'width' : 'height';
    const allSizes = activeEntities.map((entity) => getEntitySize(entity)[sizeProp]);

    const {start, end} = getBoundaries(activeEntities, positionProp, sizeProp, resolution);
    const farStart = start[positionProp];
    const farEnd = end[positionProp] + end[sizeProp];

    const totalPadding = (farEnd - farStart) - lodash.sum(allSizes);
    const numberOfSpaces = activeEntities.length - 1;
    const newPadding = totalPadding / numberOfSpaces;

    const orderedEntities = lodash.sortBy(activeEntities, (entity) => {
      const entityAlignment = getEntityPositionAlignment(entity, positionProp);
      const entityPosition = getEntityPosition(entity)[positionProp];
      const entitySize = getEntitySize(entity)[sizeProp];

      const positionOffset = 100000;
      const sizeFloor = Math.floor(entitySize);
      const positionFloor = Math.floor(adjustForAlignment(
        entityAlignment,
        entityPosition,
        entitySize,
        resolution[sizeProp]
      ));

      // Should still not overflow even for 32 bit computers unless the numbers are in the 50,000 range.
      // Allows for multi-sorting for position and size (sending array of values doesn't work for some reason).
      return (positionFloor * positionOffset) + sizeFloor;
    });

    let ongoingStart = 0;
    return orderedEntities.map((entity, index) => {
      const position = getEntityPosition(entity);
      const size = getEntitySize(entity);
      const entityAlignment = getEntityPositionAlignment(entity, positionProp);

      if (!index || index === numberOfSpaces) {
        const adjustedPosition = adjustForAlignment(
          entityAlignment,
          position[positionProp],
          size[sizeProp],
          resolution[sizeProp]
        );

        ongoingStart += adjustedPosition + size[sizeProp];
        return {entity, positionDelta: {
          [positionProp]: 0,
        }};
      }

      const newValue = adjustForAlignment(
        entityAlignment,
        ongoingStart + newPadding,
        size[sizeProp],
        resolution[sizeProp],
        true
      );

      let positionDelta;
      if (entityAlignment === ALIGN_CENTERED) {
        positionDelta = newValue - position[positionProp];
      } else if (entityAlignment === ALIGN_FAR) {
        positionDelta = position[positionProp] - newValue;
      } else {
        positionDelta = newValue - position[positionProp];
      }

      ongoingStart += newPadding + size[sizeProp];

      return {
        entity,
        positionDelta: {
          [positionProp]: positionDelta,
        },
      };
    });
  }

  /**
   * Expands the item to fit on the screen.
   *
   * @param {object[]} activeEntities
   * @param {string} alignment
   * @param {{width: number, height: number}} resolution
   * @returns {Array.<{entity: {}, positionDelta: {}, sizeDelta: {}}>}
   */
  function expandToFit(activeEntities, alignment, resolution) {
    const quarter = 4;
    const newValueX = (option.FIT_FULL === alignment) ? 0 : (resolution.width / quarter);
    const newValueY = (option.FIT_FULL === alignment) ? 0 : (resolution.height / quarter);

    const newValueWidth = (option.FIT_FULL === alignment) ? resolution.width : (resolution.width / 2);
    const newValueHeight = (option.FIT_FULL === alignment) ? resolution.height : (resolution.height / 2);

    return activeEntities.map((entity) => {
      const position = getEntityPosition(entity);
      const size = getEntitySize(entity);
      const entityAlignmentX = getEntityPositionAlignment(entity, 'x');
      const entityAlignmentY = getEntityPositionAlignment(entity, 'y');

      const adjustedPositionX = adjustForAlignment(
        entityAlignmentX,
        newValueX,
        newValueWidth,
        resolution.width,
        true
      );
      const adjustedPositionY = adjustForAlignment(
        entityAlignmentY,
        newValueY,
        newValueHeight,
        resolution.height,
        true
      );

      let positionDeltaX;
      if (entityAlignmentX === ALIGN_CENTERED) {
        positionDeltaX = adjustedPositionX - position.x;
      } else if (entityAlignmentX === ALIGN_FAR) {
        positionDeltaX = position.x - adjustedPositionX;
      } else {
        positionDeltaX = adjustedPositionX - position.x;
      }

      let positionDeltaY;
      if (entityAlignmentY === ALIGN_CENTERED) {
        positionDeltaY = adjustedPositionY - position.y;
      } else if (entityAlignmentY === ALIGN_FAR) {
        positionDeltaY = position.y - adjustedPositionY;
      } else {
        positionDeltaY = adjustedPositionY - position.y;
      }

      return {
        entity,
        positionDelta: {
          x: positionDeltaX,
          y: positionDeltaY,
        },
        sizeDelta: {
          width: (newValueWidth - size.width),
          height: (newValueHeight - size.height),
        },
      };
    });
  }

  return {
    name: ALIGNMENT_SYSTEM,
    runActions: systemRunActions
  };
}
