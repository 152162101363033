import {action, observable, runInAction} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBook, faEllipsisH, faFont} from '@fortawesome/free-solid-svg-icons';
import {faTrashCan} from '@fortawesome/free-regular-svg-icons';

import inject from '../../../../hoc/injectHoc';
import ConfirmModal from '../../../../modals/confirm/ConfirmModal';
import InputStringModal from '../../../../modals/inputString/InputStringModal';
import CollectionExampleAddEditModal from '../../../../modals/collectionExampleAddEdit/CollectionExampleAddEdit';

import './contentListActions.scss';

/**
 * The auto generated default content folder name.
 * @const {string}
 */
const DEFAULT_FOLDER_NAME = 'My Content';

/**
 * The ContentListActions component.
 */
export class ContentListActions extends React.Component {
  /**
   * Whether or not the delete modal is open.
   *
   * @type {boolean}
   */
  @observable isDeleteModalOpen = false;

  /**
   * Whether or not the name modal is open.
   *
   * @type {boolean}
   */
  @observable isNameModalOpen = false;

  /**
   * Whether or not the collection example modal is open.
   *
   * @type {boolean}
   */
  @observable isCollectionExampleModalOpen = false;

  /**
   * Whether or not the tooltip is open.
   *
   * @type {boolean}
   */
  @observable isDropdownOpen = false;

  /**
   * Toggles the dropdown.
   */
  @action onDropDownToggle = () => {
    this.isDropdownOpen = !this.isDropdownOpen;
  };

  /**
   * Closes the delete modal.
   */
  @action closeDeleteModal = () => {
    this.isDeleteModalOpen = false;
  };

  /**
   * Opens the delete confirm modal.
   * Triggered when the delete button is clicked.
   */
  @action onDelete = () => {
    this.isDeleteModalOpen = true;
  };

  /**
   * Deletes the item on the server.
   * Triggered when the delete modal is confirmed.
   *
   * @param {boolean} wasConfirmed
   */
  onDeleteCompleted = (wasConfirmed) => {
    this.closeDeleteModal();

    if (!wasConfirmed) {
      return;
    }

    const {
      contentListId,
      isCollection,
      onDelete,
      apiCollectionDeleteStore,
      apiContentFolderDeleteStore
    } = this.props;

    const afterDelete = () => {
      this.closeDeleteModal();

      if (onDelete) {
        onDelete();
      }
    };

    if (isCollection) {
      apiCollectionDeleteStore.makeRequest(contentListId);

      apiCollectionDeleteStore.getPromise().then(afterDelete);
    } else {
      apiContentFolderDeleteStore.makeRequest(contentListId);

      apiContentFolderDeleteStore.getPromise().then(afterDelete);
    }
  };

  /**
   * Closes the name modal.
   */
  @action closeNameModal = () => {
    this.isNameModalOpen = false;
  };

  /**
   * Opens the name modal.
   * Triggered when the rename button is clicked.
   */
  @action onRename = () => {
    this.isNameModalOpen = true;
  };

  /**
   * Saves the name change to the server.
   * Triggered when the name modal is submitted.
   *
   * @param {?string} newName
   */
  @action onRenameCompleted = (newName) => {
    if (!newName) {
      this.closeNameModal();
      return;
    }

    const {
      contentListId,
      isCollection,
      onRename,
      apiCollectionUpdateStore,
      apiCollectionGetContentStore,
      apiContentFolderUpdateStore,
      apiContentFolderGetContentStore
    } = this.props;

    const afterRename = () => {
      this.closeNameModal();

      if (onRename) {
        onRename();
      }
    };

    if (isCollection) {
      apiCollectionUpdateStore.makeRequest(contentListId, newName);
      apiCollectionGetContentStore.refresh(contentListId, true);

      apiCollectionUpdateStore.getPromise().then(afterRename);
    } else {
      apiContentFolderUpdateStore.makeRequest(contentListId, newName);
      apiContentFolderGetContentStore.refresh(contentListId, true);

      apiContentFolderUpdateStore.getPromise().then(afterRename);
    }
  };

  /**
   * Triggered when the content collection button is clicked.
   */
  @action onCollectionExample = () => {
    this.isCollectionExampleModalOpen = true;
  }

  /**
   * Collection Example Modal submitted
   *
   * @param {{}} collectionExampleData
   */
  @action onCollectionExampleCompleted = (collectionExampleData) => {
    if (!collectionExampleData) {
      this.isCollectionExampleModalOpen = false;
      return;
    }

    const {
      apiCollectionGetAllStore,
      apiCollectionExampleCreateStore,
      apiCollectionExampleUpdateStore,
      contentListId,
      collectionExample,
      onCollectionExample,
    } = this.props;

    const afterCollectionExample = () => {
      runInAction('successfulCreateCollectionExample', () => {
        apiCollectionGetAllStore.refresh(true);
        apiCollectionGetAllStore.getPromise().then(() => {
          if (onCollectionExample) {
            onCollectionExample();
          }
        });
        this.isCollectionExampleModalOpen = false;
      });
    };

    if (collectionExample) {
      apiCollectionExampleUpdateStore.makeRequest(collectionExample.id, {
        url: collectionExampleData.url,
        thumbnailFileId: collectionExampleData.thumbnailFile.id,
        videoFileId: collectionExampleData.videoFile.id,
        enabled: collectionExampleData.enabled,
      });
      apiCollectionExampleUpdateStore.getPromise().then(afterCollectionExample);
    } else {
      apiCollectionExampleCreateStore.makeRequest(contentListId, {
        url: collectionExampleData.url,
        thumbnailFileId: collectionExampleData.thumbnailFile.id,
        videoFileId: collectionExampleData.videoFile.id,
        enabled: collectionExampleData.enabled,
      });
      apiCollectionExampleCreateStore.getPromise().then(afterCollectionExample);
    }
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {canBeDeleted, contentListName, isCollection, user, collectionExample} = this.props;

    if (!isCollection && contentListName === DEFAULT_FOLDER_NAME) {
      return null;
    }

    const typeName = (isCollection) ? 'Collection' : 'Folder';
    const typeNameLower = typeName.toLowerCase();
    const canDesign = Boolean(user && user.canDesign);

    if (isCollection && !canDesign) {
      return null;
    }

    return (
      <div className="content-list-actions">
        <Dropdown isOpen={this.isDropdownOpen} toggle={this.onDropDownToggle}>
          <DropdownToggle className="btn btn-link btn-icon category-actions-open" tag="button">
            <FontAwesomeIcon
              className="icon-bold"
              icon={faEllipsisH}
            />
          </DropdownToggle>

          <span className="dropdown-filler" />

          <DropdownMenu right>
            <DropdownItem onClick={this.onRename}>
              <FontAwesomeIcon
                className="mr-2"
                icon={faFont}
              />
              Rename
            </DropdownItem>

            {(isCollection) && (
              <DropdownItem onClick={this.onCollectionExample}>
                <FontAwesomeIcon
                  className="mr-2"
                  icon={faBook}
                />
                Collection Example
              </DropdownItem>
            )}

            <DropdownItem onClick={this.onDelete}>
              <FontAwesomeIcon
                className="mr-2"
                icon={faTrashCan}
              />
              Delete
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>

        {(this.isNameModalOpen) && (
          <InputStringModal
            isOpen={true}
            onComplete={this.onRenameCompleted}
            placeholder={`New ${typeName} Name`}
            title={`New ${typeName} Name`}
          />
        )}

        {(this.isCollectionExampleModalOpen) && (
          <CollectionExampleAddEditModal
            onComplete={this.onCollectionExampleCompleted}
            collectionExample={collectionExample}
          />
        )}

        {(canBeDeleted && this.isDeleteModalOpen) && (
          <ConfirmModal
            isOpen={true}
            onComplete={this.onDeleteCompleted}
            title={`Delete this ${typeName}?`}
            confirmText={`Are you sure you want to delete the ${typeNameLower} '${contentListName}'?`}
            isYesNo={true}
          />
        )}

        {(!canBeDeleted && this.isDeleteModalOpen) && (
          <ConfirmModal
            isOpen={true}
            onComplete={this.closeDeleteModal}
            title="Can Not Delete"
            confirmText={
              `The ${typeNameLower} '${contentListName}' can not be deleted as long as it is holding content.`
            }
            isYesNo={false}
          />
        )}
      </div>
    );
  }
}

ContentListActions.propTypes = {
  contentListId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  contentListName: PropTypes.string.isRequired,

  apiCollectionDeleteStore: MobxPropTypes.observableObject,
  apiCollectionExampleCreateStore: MobxPropTypes.observableObject,
  apiCollectionExampleUpdateStore: MobxPropTypes.observableObject,
  apiCollectionGetAllStore: MobxPropTypes.observableObject,
  apiCollectionGetContentStore: MobxPropTypes.observableObject,
  apiCollectionUpdateStore: MobxPropTypes.observableObject,
  apiContentFolderDeleteStore: MobxPropTypes.observableObject,
  apiContentFolderGetContentStore: MobxPropTypes.observableObject,
  apiContentFolderUpdateStore: MobxPropTypes.observableObject,
  canBeDeleted: PropTypes.bool,
  collectionExample: PropTypes.object,
  isCollection: PropTypes.bool,
  onCollectionExample: PropTypes.func,
  onDelete: PropTypes.func,
  onRename: PropTypes.func,
  user: PropTypes.object,
};

ContentListActions.defaultProps = {
  canBeDeleted: false,
  isCollection: false,
};

ContentListActions.wrappedComponent = {};
ContentListActions.wrappedComponent.propTypes = {
  apiCollectionDeleteStore: MobxPropTypes.observableObject.isRequired,
  apiCollectionExampleCreateStore: MobxPropTypes.observableObject.isRequired,
  apiCollectionExampleUpdateStore: MobxPropTypes.observableObject.isRequired,
  apiCollectionGetAllStore: MobxPropTypes.observableObject.isRequired,
  apiCollectionGetContentStore: MobxPropTypes.observableObject.isRequired,
  apiCollectionUpdateStore: MobxPropTypes.observableObject.isRequired,
  apiContentFolderDeleteStore: MobxPropTypes.observableObject.isRequired,
  apiContentFolderGetContentStore: MobxPropTypes.observableObject.isRequired,
  apiContentFolderUpdateStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(ContentListActions)(
  observer(ContentListActions)
);
