/* eslint-disable no-magic-numbers */

import lodash from 'lodash';
import {runInAction, toJS} from 'mobx';
import uuidv4 from 'uuid/v4';

import {actionComponent} from '../components/common/actionComponent';
import {getComposeFromSource} from '../components/common/composeComponent';
import {getCropFromSource} from '../components/common/cropComponent';
import {getCropShapeFromSource} from '../components/common/cropShapeComponent';
import {getEffectFromSource} from '../components/common/effectComponent';
import {getElementFromSource} from '../components/common/elementComponent';
import {getGroupFromSource} from '../components/common/groupComponent';
import {getInteractionFromSource} from '../components/common/interactionComponent';
import {getLockedFromSource} from '../components/common/lockedComponent';
import {getMaskFromSource} from '../components/common/maskComponent';
import {getNameFromSource} from '../components/common/nameComponent';
import {ALIGNMENTS, getPositionFromSource} from '../components/common/positionComponent';
import {getSizeFromSource} from '../components/common/sizeComponent';
import {getTimeFromSource} from '../components/common/timeComponent';
import {getTransformFromSource} from '../components/common/transformComponent';
import {getTransitionFromSource} from '../components/common/transitionComponent';
import {getVisibleFromSource} from '../components/common/visibleComponent';

import {getAudioFromSource} from '../components/type/audioComponent';
import {getCircleFromSource} from '../components/type/circleComponent';
import {getIconFromSource} from '../components/type/iconComponent';
import {getImageFromSource} from '../components/type/imageComponent';
import {getLineFromSource} from '../components/type/lineComponent';
import {getRectangleFromSource} from '../components/type/rectangleComponent';
import {getTriangleFromSource} from '../components/type/triangleComponent';
import {getFeedFromSource} from '../components/type/feedComponent';
import {getFeedIconFromSource} from '../components/type/feedIconComponent';
import {getTextFromSource} from '../components/type/textComponent';
import {getTimerFromSource, timerComponent} from '../components/type/timerComponent';
import {getVideoFromSource} from '../components/type/videoComponent';

import {ALIGN_FAR, ALIGN_CENTERED, ALIGN_NEAR} from '../../constants/entityConstants';
import {mobxMerge} from '../../utils/mobxHelper';

/**
 * The number to divide by to convert to a percentage.
 *
 * @const {number}
 */
const TO_PERCENT = 100;

/**
 * Parses the item into common components.
 *
 * @param {{}} item
 * @param {number} order
 * @param {{getEndTime: function, getFps: function, getResolution: function}} game
 * @returns {{}}
 */
function getCommonComponents(item, order, game) {
  return Object.assign(
    {},
    getElementFromSource(item),
    getComposeFromSource(item),
    getCropFromSource(item),
    getCropShapeFromSource(item),
    getEffectFromSource(item),
    getGroupFromSource(item),
    getInteractionFromSource(item),
    getLockedFromSource(item),
    getMaskFromSource(item),
    getNameFromSource(item),
    getPositionFromSource(item, order),
    getSizeFromSource(item),
    getTimeFromSource(item, game.fps),
    getTransformFromSource(item),
    (game.hasTimeLine()) ? getTransitionFromSource(item, game.fps) : {},
    getVisibleFromSource(item)
  );
}

/**
 * Gets an entity from the source file item.
 *
 * @param {{}} item
 * @param {number} order
 * @param {GameStore} game
 * @param {{}=} variables
 * @returns {{}}
 */
export function getEntityFromSourceItem(item, order, game, variables) {
  const fps = game.fps;
  if (fps < 10 || fps > 60) {
    throw new Error('Invalid fps given to entity helper, value must be between 10 and 60 (inclusive).');
  }

  // Make sure all entities have a start and end time.
  if (!item.startTime) {
    item.startTime = 0;
  }

  const entity = {
    id: uuidv4()
  };

  const commonComponents = getCommonComponents(item, order, game);

  let typeComponents = null;
  switch (item.type) {
    case 'audio':
      typeComponents = getAudioFromSource(item, variables);
      break;
    case 'circle':
      typeComponents = getCircleFromSource(item, variables);
      break;
    case 'icon':
      typeComponents = getIconFromSource(item, variables);
      break;
    case 'image':
      typeComponents = getImageFromSource(item, variables);
      break;
    case 'line':
      typeComponents = getLineFromSource(item, variables);
      break;
    case 'rectangle':
      typeComponents = getRectangleFromSource(item, variables);
      break;
    case 'triangle':
      typeComponents = getTriangleFromSource(item, variables);
      break;
    case 'text':
      typeComponents = getTextFromSource(item, variables);
      break;
    case 'feed':
      typeComponents = getFeedFromSource(item, variables);
      break;
    case 'feedicon':
      typeComponents = getFeedIconFromSource(item, variables);
      break;
    case 'timer':
      typeComponents = getTimerFromSource(item);
      break;
    case 'video':
      typeComponents = getVideoFromSource(item, variables);
      break;
    default:
      throw new Error(`Invalid entity type '${item.type}' found.`);
  }

  return Object.assign(
    entity,
    commonComponents,
    typeComponents
  );
}

/**
 * Gets an action entity for the game.
 *
 * @param {{entityId: (string|string[])}} actionParams
 * @param {Object.<string, {}>} components
 * @returns {{id: string, element: string}}
 */
export function getActionEntity(actionParams, components) {
  return Object.assign({
    id: uuidv4(),
    element: 'action',
  }, components || {}, actionComponent(actionParams));
}

/**
 * Gets a timer entity for the game.
 *
 * @param {number} maxTime
 * @returns {{id: string, element: string, timer: {time: number, state: string}}}
 */
export function getTimerEntity(maxTime) {
  return Object.assign({
    id: uuidv4(),
    element: 'timer',
  }, timerComponent(maxTime));
}

/**
 * Creates a new entity with all the same components.
 *
 * @param {ObservableMap} entity
 * @returns {{}}
 */
export function cloneEntity(entity) {
  const newEntity = toJS(entity);
  newEntity.id = uuidv4();
  delete newEntity.interaction;

  return newEntity;
}

/**
 * Updates the entity with the given values.
 *
 * @param {{get: function(string)}} entity
 * @param {string} componentName
 * @param {{}} newValues
 * @param {string=} mobXName
 */
export function updateEntity(entity, componentName, newValues, mobXName) {
  if (!entity.get(componentName)) {
    return;
  }

  runInAction(mobXName || 'updateEntity', () => {
    if (!newValues) {
      entity.delete(componentName);
      return;
    }

    // This will mutate the component.
    mobxMerge(entity.get(componentName), newValues);
  });
}

/**
 * Clears the caching for a transition.
 *
 * @param {{}} entity
 */
export function clearTransitionCache(entity) {
  if (!entity.has('transition')) {
    return;
  }

  runInAction('updateEntity', () => {
    entity.get('transition').forEach((transition) => {
      transition.loadedPreset = false;
      transition.initialized = false;
    });
  });
}

/**
 * Sets one or more components on an entity.
 *
 * @param {{set: function}} entity
 * @param {Object.<string, {}>} newComponents
 */
export function setEntityComponents(entity, newComponents) {
  runInAction('setEntityComponents', () => {
    lodash.forEach(newComponents, (componentData, componentName) => {
      entity.set(componentName, componentData);
    });
  });
}

/**
 * Gets an entity position object.
 *
 * @param {{}} entity
 * @returns {{top: number, left: number}}
 */
export function getEntityPosition(entity) {
  if (entity.get('element') === 'image') {
    return entity.get('image');
  }
  return entity.get('position');
}

/**
 * Gets an entity size object.
 *
 * @param {{}} entity
 * @returns {{width: number, height: number}}
 */
export function getEntitySize(entity) {
  if (entity.get('element') === 'image') {
    return entity.get('image');
  }
  return entity.get('size');
}

/**
 * Gets an entity position alignment values.
 *
 * @param {{}} entity
 * @param {string} alignmentProp
 * @returns {Symbol}
 */
export function getEntityPositionAlignment(entity, alignmentProp) {
  const axisAlignment = lodash.get(entity.get('position'), `alignment.${alignmentProp}`);

  if (axisAlignment === ALIGNMENTS.x.center || axisAlignment === ALIGNMENTS.y.middle) {
    return ALIGN_CENTERED;
  } else if (axisAlignment === ALIGNMENTS.x.right || axisAlignment === ALIGNMENTS.y.bottom) {
    return ALIGN_FAR;
  }

  return ALIGN_NEAR;
}

/**
 * Adjusts for the entity alignment.
 * Defaults adjusting from the entity alignment to the left/top alignment.
 *
 * @param {Symbol} entityAlignment
 * @param {number} position
 * @param {number} size
 * @param {number} resolution
 * @param {boolean} reverse From left/top alignment to the entity alignment.
 * @returns {number}
 */
export function adjustForAlignment(entityAlignment, position, size, resolution, reverse) {
  if (reverse) {
    if (entityAlignment === ALIGN_CENTERED) {
      return position - (resolution / 2) + (size / 2);
    } else if (entityAlignment === ALIGN_FAR) {
      return resolution - position - size;
    }
    return position;
  }

  if (entityAlignment === ALIGN_CENTERED) {
    return position + (resolution / 2) - (size / 2);
  } else if (entityAlignment === ALIGN_FAR) {
    return resolution - position - size;
  }
  return position;
}

/**
 * Gets the most horizontal edges of the entities.
 *
 * @param {Array.<{}>} activeEntities
 * @param {string} positionProp
 * @param {string} sizeProp
 * @param {{height: number, width: number}} resolution
 * @returns {{position: number, size: number, start: {}, end: {}}}
 */
export function getBoundaries(activeEntities, positionProp, sizeProp, resolution) {
  const start = {
    [positionProp]: 9999999,
  };
  const end = {
    [positionProp]: 0,
    [sizeProp]: 0,
  };

  let farthestPoint = 0;
  activeEntities.forEach((entity) => {
    const position = getEntityPosition(entity);
    const size = getEntitySize(entity);
    const entityAlignment = getEntityPositionAlignment(entity, positionProp);

    const adjustedPosition = adjustForAlignment(
      entityAlignment,
      position[positionProp],
      size[sizeProp],
      resolution[sizeProp]
    );

    if (adjustedPosition < start[positionProp]) {
      start[positionProp] = adjustedPosition;
    }

    const currentFarthestPoint = adjustedPosition + size[sizeProp];
    if (currentFarthestPoint <= farthestPoint) {
      return;
    }

    farthestPoint = currentFarthestPoint;
    end[positionProp] = adjustedPosition;
    end[sizeProp] = size[sizeProp];
  });

  const positionValue = start[positionProp];
  const sizeValue = end[positionProp] + end[sizeProp] - start[positionProp];

  return {start, end, position: positionValue, size: sizeValue};
}

/**
 * Gets the entity position in terms of left and top.
 *
 * @param {{x: string, y: string}} alignment
 * @param {{alignment: {x: string, y: string}, x: number, y: number}} position
 * @param {{height: number, width: number}} size
 * @param {{resolution: {height: number, width: number}}} game
 * @returns {{left: number, top: number}}
 */
export function getPositionLeftAndTop(alignment, position, size, game) {
  if (!position) {
    return {top: undefined, left: undefined};
  } else if (!alignment) {
    return {
      top: position.y,
      left: position.x,
    };
  }

  const resolution = game.resolution;
  const style = {
    top: undefined,
    left: undefined,
  };

  if (position.y !== undefined) {
    const positionY = Number.parseFloat(position.y);

    if (alignment.y === ALIGNMENTS.y.bottom) {
      style.top = (resolution.height - size.height - positionY);
    } else if (alignment.y === ALIGNMENTS.y.middle) {
      style.top = (resolution.height / 2) - (size.height / 2) + positionY;
    } else {
      style.top = positionY;
    }
  }

  if (position.x !== undefined) {
    const positionX = Number.parseFloat(position.x);

    if (alignment.x === ALIGNMENTS.x.right) {
      style.left = (resolution.width - size.width - positionX);
    } else if (alignment.x === ALIGNMENTS.x.center) {
      style.left = (resolution.width / 2) - (size.width / 2) + positionX;
    } else {
      style.left = positionX;
    }
  }

  return style;
}

/**
 * Adjusts default parameters for a size, position, image, crop component (if applicable).
 *
 * @param {{}} component
 * @param {{height: number, width: number}} gameResolution
 * @returns {{}}
 */
export function adjustParamsDefaults(component, gameResolution) {
  if (!component.default) {
    return component;
  }

  const defaultValues = component.default;
  const decimalLimit = 4;
  const newComponent = lodash.cloneDeep(component);

  if (defaultValues.widthIsPercent) {
    const toPercent = (Number.parseFloat(component.width) / gameResolution.width) * TO_PERCENT;
    newComponent.default.width = Number.parseFloat(toPercent).toFixed(decimalLimit) + '%';
  } else if (defaultValues.width || defaultValues.width === 0) {
    newComponent.default.width = component.width;
  }
  if (defaultValues.heightIsPercent) {
    const toPercent = (Number.parseFloat(component.height) / gameResolution.height) * TO_PERCENT;
    newComponent.default.height = Number.parseFloat(toPercent).toFixed(decimalLimit) + '%';
  } else if (defaultValues.height || defaultValues.height === 0) {
    newComponent.default.height = component.height;
  }
  if (defaultValues.xIsPercent) {
    const toPercent = (Number.parseFloat(component.x) / gameResolution.width) * TO_PERCENT;
    newComponent.default.x = Number.parseFloat(toPercent).toFixed(decimalLimit) + '%';
  } else if (defaultValues.x || defaultValues.x === 0) {
    newComponent.default.x = component.x;
  }
  if (defaultValues.yIsPercent) {
    const toPercent = (Number.parseFloat(component.y) / gameResolution.height) * TO_PERCENT;
    newComponent.default.y = Number.parseFloat(toPercent).toFixed(decimalLimit) + '%';
  } else if (defaultValues.y || defaultValues.y === 0) {
    newComponent.default.y = component.y;
  }

  return newComponent;
}

/**
 * Gets the percentage value of an image value.
 *
 * @param {number} pixelValue
 * @param {string} percentage
 * @param {boolean} onlyAllowString If true and the percentage does not contain a %,
 *                                  then the value will be returned unchanged.
 * @returns {number}
 */
export function getPixelFromPercentage(pixelValue, percentage, onlyAllowString) {
  if (onlyAllowString && String(percentage).indexOf('%') === -1) {
    return percentage;
  }

  const safePixelValue = pixelValue || 0;

  let percentValue = percentage || 0;
  if (String(percentage).indexOf('%') !== -1) {
    percentValue = Number(parseFloat(percentage)) / TO_PERCENT;
  }

  return safePixelValue * percentValue;
}

/**
 * Turns the pixels in percentage.
 *
 * @param {number} sourcePixel
 * @param {number} pixelValue
 * @returns {number}
 */
export function getPercentageFromPixel(sourcePixel, pixelValue) {
  const safePixelValue = pixelValue || 0;

  return (safePixelValue / sourcePixel) * TO_PERCENT;
}

/**
 * Parses transform properties into a css transform string.
 *
 * @param {{}} transforms
 * @param {{}=} defaults
 * @returns {{transform: string, perspectiveOrigin: string}}
 */
export function parseCSSTransforms(transforms, defaults) {
  const validStarts = ['perspective', 'perspectiveOrigin', 'rotate', 'scale', 'skew', 'translate'];
  const properties2D = ['scale', 'skew', 'translate'];

  const transformStyle = [];
  const otherStyles = {};
  lodash.forEach(transforms, (propertyValue, propertyName) => {
    const transformName = lodash.find(validStarts, (validStart) => {
      return lodash.startsWith(propertyName, validStart);
    });
    if (!transformName) {
      // This property is not valid.
      return;
    }

    if (lodash.endsWith(propertyName, '3d')) {
      const threeDValue = parse3DTransform(propertyName, propertyValue, transformName);
      if (threeDValue) {
        transformStyle.push(threeDValue);
      }
      return;
    } else if (propertyName === 'perspectiveOrigin') {
      const originValue = parsePerspectiveOrigin(propertyValue);
      if (originValue) {
        otherStyles.perspectiveOrigin = originValue;
      }
      return;
    }

    let safeValue = propertyValue || 0;
    if (!propertyValue && defaults && defaults[propertyName]) {
      safeValue = defaults[propertyName];
    }

    if (transformName !== 'scale' && !safeValue) {
      // Every property but scale has the same value at 0 as not declaring it.
      return;
    } else if (transformName === 'scale' && String(safeValue) === '1') {
      // A scale value of 1 is the same as no scale value.
      return;
    }

    safeValue = appendUnitsToTransform(transformName, safeValue);

    if (lodash.includes(properties2D, propertyName)) {
      transformStyle.push(`${propertyName}(${safeValue}, ${safeValue})`);
    } else {
      transformStyle.push(`${propertyName}(${safeValue})`);
    }
  });

  return {
    ...otherStyles,
    transform: transformStyle.join(' ')
  };
}

/**
 * Appends the units to the transform value.
 *
 * @param {string} transformName
 * @param {number} value
 * @returns {string}
 */
function appendUnitsToTransform(transformName, value) {
  let safeValue = String(value);

  const pxNames = ['translate', 'perspective'];
  const degNames = ['skew', 'rotate'];

  if (lodash.includes(pxNames, transformName)) {
    safeValue += 'px';
  } else if (lodash.includes(degNames, transformName)) {
    safeValue += 'deg';
  }

  return safeValue;
}

/**
 * Parses a 3d transform into its css string.
 *
 * @param {string} propertyName
 * @param {{x: number, y: number, z: number, angle: number}} values
 * @param {string} transformName
 * @returns {?string}
 */
function parse3DTransform(propertyName, values, transformName) {
  const defaultValue = (transformName === 'scale') ? 1 : 0;

  const parts = [
    values.x || defaultValue,
    values.y || defaultValue,
    values.z || defaultValue,
  ];

  if (transformName === 'scale') {
    if (parts[0] === 1 && parts[1] === 1 && parts[2] === 1) {
      return null;
    }
  } else if (parts[0] === 0 && parts[1] === 0 && parts[2] === 0) {
    return null;
  }

  if (transformName === 'rotate') {
    const safeAngle = values.angle || 0;
    parts.push(`${safeAngle}deg`);
  } else if (transformName === 'translate') {
    parts[0] += 'px';
    parts[1] += 'px';
    parts[2] += 'px';
  }

  return `${propertyName}(${parts.join(',')})`;
}

/**
 * Parses the perspective origin x and y values into its css string.
 *
 * @param {{x: number, y: number}} values
 * @returns {string}
 */
function parsePerspectiveOrigin(values) {
  const parts = [
    values.x || 0,
    values.y || 0,
  ];

  if (parts[0] === 0 && parts[1] === 0) {
    return null;
  }

  parts[0] += '%';
  parts[1] += '%';

  return parts.join(' ');
}

/**
 * Parses mask properties into a css filter string.
 *
 * @param {{}} masks
 * @returns {{filter: string}}
 */
export function parseCSSFilters(masks) {
  const validProps = ['blur', 'brightness', 'contrast', 'hue-rotate', 'invert', 'opacity', 'saturate', 'sepia'];

  const maskStyle = [];
  lodash.forEach(masks, (propertyValue, propertyName) => {
    if (!propertyName || !lodash.includes(validProps, propertyName)) {
      return;
    } else if (propertyName === 'contrast' || propertyName === 'brightness' || propertyName === 'opacity') {
      if (propertyValue === 1 || propertyValue === '100%') {
        return;
      }
    } else if (!propertyValue) {
      // Every property but contrast and brightness has the same value at 0 as not declaring it.
      return;
    }

    let safeValue = String(propertyValue || 0);
    if (propertyName === 'blur') {
      safeValue += 'px';
    } else if (propertyName === 'hue-rotate') {
      safeValue += 'deg';
    }

    maskStyle.push(`${propertyName}(${safeValue})`);
  });

  return {filter: maskStyle.join(' ')};
}

/**
 * Parses effect properties into a css style properties.
 *
 * @param {{}} effects
 * @returns {{}}
 */
export function parseCSSEffects(effects) {
  const validProps = [{name: 'blendMode', styleName: 'mixBlendMode'}];

  const styles = {};
  lodash.forEach(validProps, (effectData) => {
    const effectName = effectData.name;

    if (!effects[effectName]) {
      return;
    }

    const styleName = effectData.styleName;
    styles[styleName] = effects[effectName];
  });

  return styles;
}
