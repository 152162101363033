import lodash from 'lodash';
import {action, observable, runInAction, toJS} from 'mobx';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import ConfirmModal from '../../../../modals/confirm/ConfirmModal';
import SelectSignAndFileTypeModal from '../../../../modals/selectSignAndFileType/SelectSignAndFileTypeModal';
import inject from '../../../../hoc/injectHoc';
import SelectFolderModal from '../../../../modals/selectFolder/SelectFolderModal';
import DisplaySidebar from '../../../../sidebars/display/DisplaySidebar';
import {STATE_PENDING} from '../../../../../constants/asyncConstants';
import {CONTENT_LIST_FOLDER} from '../../../../../constants/contentConstants';
import {getSourceFromGame} from '../../../../../display/game';

import './editorSide.scss';

/**
 * The EditorSide component.
 *
 * @property {{
 *   apiContentCreateStore: ApiContentCreateStore,
 *   apiContentUpdateStore: ApiContentUpdateStore,
 *   displayEditorStore: DisplayEditorStore,
 * }} props
 */
export class EditorSide extends React.Component {
  /**
   * Whether or not the folder modal is open.
   *
   * @type {boolean}
   */
  @observable isFolderModalOpen = false;

  /**
   * Whether or not the dropdown for the save options is open.
   *
   * @type {boolean}
   */
  @observable isSaveAlertOpen = false;

  /**
   * Whether or not the select sign modal is open.
   *
   * @type {boolean}
   */
  @observable isSelectSignModalOpen = false;

  /**
   * Content to be downloaded.
   *
   * @type {{}}
   */
  @observable contentToDownload = {};

  /**
   * Sign id to preselect when opening select sign modal
   *
   * @type {number|null}
   */
  @observable preselectedSignId = null;

  /**
   * Whether or not the template should download after saving.
   *
   * @type {boolean}
   */
  @observable downloadAfterSaving = false;

  /**
   * The save alert message.
   *
   * @type {string}
   */
  @observable saveAlertMessage = '';

  /**
   * Triggered when the component is added to the page.
   */
  async componentDidMount() {
    const {apiCompanySignGetAllStore} = this.props;

    apiCompanySignGetAllStore.refresh();
  }

  /**
   * Closes the save alert.
   */
  @action closeAlertModal = () => {
    this.isSaveAlertOpen = false;
  };

  /**
   * Saves the source JSON to the database.
   *
   * @returns {Promise}
   */
  saveVideo = () => {
    const {content, apiContentUpdateStore, displayEditorStore} = this.props;

    const {game} = displayEditorStore;

    // Update the current source before saving all the sources.
    displayEditorStore.setSource(displayEditorStore.currentAspectRatio, getSourceFromGame(game));

    const sources = toJS(displayEditorStore.sources);
    const variables = toJS(displayEditorStore.variables);

    const cleanVariables = this.cleanUpVariables(sources, variables);

    apiContentUpdateStore.updateActiveContentSource(
      content.id,
      sources,
      cleanVariables,
      content.isFree,
      content.isDraft,
      content.isUserMade,
    );

    return apiContentUpdateStore.getPromise();
  };

  /**
   * Saves a new content to the database including variables and source.
   *
   * @param {?number} folderId
   * @param {string} contentName
   * @returns {Promise}
   */
  saveNewVideo = (folderId, contentName) => {
    const {content, apiContentCreateStore, displayEditorStore} = this.props;

    const {game} = displayEditorStore;

    // Update the current source before saving all the sources.
    displayEditorStore.setSource(displayEditorStore.currentAspectRatio, getSourceFromGame(game));

    const sources = toJS(displayEditorStore.sources);
    const variables = toJS(displayEditorStore.variables);

    const cleanVariables = this.cleanUpVariables(sources, variables);

    apiContentCreateStore.makeRequest({
      contentFolderId: folderId,
      duration: game.endTime,
      height: content.height,
      name: contentName,
      sources,
      variables: cleanVariables,
      width: content.width,
      isFree: content.isFree,
      isUserMade: content.isUserMade,
    });
    return apiContentCreateStore.getPromise();
  };

  /**
   * Removes any items from the variables that are not used in any sources.
   *
   * @param {Object.<string, {}>} sources
   * @param {Object.<string, {}>} variables
   * @returns {Object.<string, {}>}
   */
  cleanUpVariables = (sources, variables) => {
    const activeVariables = {};
    lodash.forEach(sources || [], (sourceData) => {
      lodash.forEach(sourceData.entities || [], (entity) => {
        const compose = entity.compose;
        const element = entity.type;

        if (!compose || !compose.variableName) {
          return;
        }

        const path = `${element}.${compose.variableName}`;
        activeVariables[path] = true;
      });
    });

    let cleanVariables = {};
    lodash.forEach(variables, (groupItems, groupName) => {
      lodash.forEach(groupItems, (variableValue, variableName) => {
        const path = `${groupName}.${variableName}`;
        if (!lodash.get(activeVariables, path, false)) {
          return;
        }

        cleanVariables = {
          ...cleanVariables,
          [groupName]: {
            ...cleanVariables[groupName],
            [variableName]: variableValue
          }
        };
      });
    });

    return cleanVariables;
  };

  /**
   * Triggered when the user clicks to save the video.
   *
   * @param {{}} clickEvent
   */
  @action onSaveVideoClick = (clickEvent) => {
    clickEvent.preventDefault();

    const {content} = this.props;

    if (!content.id || content.isGlobal) {
      // New content and global content will create a new content record, so have them select their folder and name.
      this.isFolderModalOpen = true;
      return;
    }

    this.saveVideo().then(() => {
      return this.afterSave();
    }).catch((saveError) => {
      return this.afterSave(true, saveError);
    });
  };

  /**
   * Triggered when the user clicks to `save as` the video.
   *
   * @param {{}} clickEvent
   */
  @action onSaveAsVideoClick = (clickEvent) => {
    clickEvent.preventDefault();

    this.isFolderModalOpen = true;
  };

  /**
   * Triggered when the user clicks to `publish` the video.
   *
   * @param {{}} clickEvent
   */
  @action onDownloadContentClick = (clickEvent) => {
    clickEvent.preventDefault();

    const {content, displayEditorStore, apiCompanySignGetAllStore} = this.props;
    this.contentToDownload = content;

    if (!content.id || content.isGlobal) {
      this.downloadAfterSaving = true;

      // New content and global content will create a new content record, so have them select their folder and name.
      this.isFolderModalOpen = true;
      return;
    }

    this.saveVideo().then(() => {
      return apiCompanySignGetAllStore.getPromise();
    }).then((signs) => {
      runInAction('saveVideoCompleted', () => {
        const currentAspectRatio = displayEditorStore.currentAspectRatio;
        const firstSignWithMatchingAspectRatio = signs.find((sign) => sign.aspectRatio === currentAspectRatio);

        this.preselectedSignId = firstSignWithMatchingAspectRatio
          ? firstSignWithMatchingAspectRatio.id
          : null;
        this.isSelectSignModalOpen = true;
      });
    }).catch((saveError) => {
      this.afterSave(true, saveError);
    });
  }

  /**
   * Closes the collection data and saves the content.
   *
   * @param {{}} param
   * @param {number} param.folderId
   * @param {string} param.contentName
   */
  @action closeFolderModal = ({folderId, contentName}) => {
    this.isFolderModalOpen = false;

    if (!contentName) {
      return;
    }

    const {onCreated, displayEditorStore, apiCompanySignGetAllStore} = this.props;

    let newContent;
    this.saveNewVideo(folderId, contentName).catch(() => {
      runInAction('saveVideoErrorToAlert', () => {
        this.saveAlertMessage = 'An error occurred while trying to save this video.'
          + ' Please wait a few minutes and try again.';
        this.isSaveAlertOpen = true;
      });

      return null;
    }).then((content) => {
      newContent = content;
      if (newContent && newContent.id && !this.downloadAfterSaving) {
        onCreated(newContent.id);
      }

      return apiCompanySignGetAllStore.getPromise();
    }).then((signs) => {
      if (this.downloadAfterSaving) {
        runInAction('saveVideoCompleted', () => {
          const currentAspectRatio = displayEditorStore.currentAspectRatio;
          const firstSignWithMatchingAspectRatio = signs.find((sign) => sign.aspectRatio === currentAspectRatio);

          this.preselectedSignId = firstSignWithMatchingAspectRatio
            ? firstSignWithMatchingAspectRatio.id
            : null;

          this.contentToDownload = newContent;
          this.isSelectSignModalOpen = true;
        });
      }
    });
  };

  /**
   * Closes the select sign modal.
   *
   * @param {Object} content
   * @param {number|null} signId
   * @param {string} fileType
   */
  @action closeSelectSignModal = ({
    content,
    signId,
    fileType,
  }) => {
    const {unblockRouterTransition, apiContentRenderStore, navigateContentStore} = this.props;

    if (signId && fileType) {
      apiContentRenderStore.makeRequest(content.id, fileType, signId);

      // Don't show unsaved changes warning since template was just saved.
      unblockRouterTransition();

      // Navigate to the render status page
      navigateContentStore.navigateToDashboard(null, {
        contentId: content.id,
        listType: CONTENT_LIST_FOLDER,
        listId: content.contentFolderId,
      });
    }
    this.isSelectSignModalOpen = false;
  }

  /**
   * Logic to run after a save.
   *
   * @param {boolean} isError
   * @param {Error=} saveError
   */
  @action afterSave = (isError, saveError) => {
    if (!isError) {
      runInAction('saveVideoSuccessToAlert', () => {
        this.saveAlertMessage = 'The template was saved successfully.';
        this.isSaveAlertOpen = true;
      });
      return;
    }

    const {apiUserGetMeStore} = this.props;

    const user = apiUserGetMeStore.getFulfilled();

    const canShowError = Boolean(saveError && user && user.canDesign);

    runInAction('saveVideoErrorToAlert', () => {
      if (canShowError) {
        this.saveAlertMessage = saveError.message;

        if (saveError.fieldErrors) {
          this.saveAlertMessage += ' -- ' + JSON.stringify(saveError.fieldErrors);
        }
      } else {
        this.saveAlertMessage = 'An error occurred while trying to save this video.'
          + ' Please wait a few minutes and try again.';
      }

      this.isSaveAlertOpen = true;
    });
  };

  /**
   * Renders the component.
   *
   * @returns {{}}
   */
  render() {
    const {content, apiContentCreateStore, apiContentUpdateStore} = this.props;

    const currentStates = [apiContentCreateStore.getState(), apiContentUpdateStore.getState()];
    const isSavePending = lodash.includes(currentStates, STATE_PENDING);

    return (
      <div id="editor-side" className="system-sidebar">
        <div className="editor-side-active">
          <DisplaySidebar canMinimize={false} isCreator />
        </div>

        <div className="editor-side-actions">
          <div className="btn-group dropup save-button-group" role="group">
            <button
              className="btn btn-light action-button save-button"
              type="button"
              disabled={isSavePending}
              onClick={this.onDownloadContentClick}
            >
              {(isSavePending) ? 'Creating...' : 'Create'}
            </button>

            <button
              type="button"
              className="btn btn-dark-blue dropdown-toggle color-inherit-caret dropdown-toggle-split border-left"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span className="sr-only">Toggle Dropdown</span>
            </button>

            <div className="dropdown-menu">
              <a className="dropdown-item" onClick={this.onSaveVideoClick}>Save Draft</a>
              <a className="dropdown-item" onClick={this.onSaveAsVideoClick}>Save As</a>
            </div>
          </div>
        </div>

        {(this.isSaveAlertOpen) && (
          <ConfirmModal
            isOpen={true}
            confirmText={this.saveAlertMessage}
            isYesNo={false}
            title="After Saved"
            onComplete={this.closeAlertModal}
          />
        )}

        {(this.isFolderModalOpen) && (
          <SelectFolderModal
            defaultName={(content && content.name) ? content.name : ''}
            isOpen={true}
            onComplete={this.closeFolderModal}
          />
        )}

        {(this.isSelectSignModalOpen) && (
          <SelectSignAndFileTypeModal
            isOpen={this.isSelectSignModalOpen}
            content={this.contentToDownload}
            modalBodyUpperSlot={(<p className="small">
              Your design will be sized to the selected sign and format:
            </p>)}
            modalBodyLowerSlot={(<p className="small">
              Once created, <span className="font-weight-bold">download</span> or <span className="font-weight-bold">schedule</span> how you prefer!
            </p>)}
            modalHeaderText="Select a Sign to Create For"
            onComplete={this.closeSelectSignModal}
            preselectedSignId={this.preselectedSignId}
          />
        )}
      </div>
    );
  }
}

EditorSide.propTypes = {
  content: PropTypes.shape({
    height: PropTypes.number,
    id: PropTypes.number,
    name: PropTypes.string,
    width: PropTypes.number,
  }).isRequired,
  onCreated: PropTypes.func.isRequired,
  unblockRouterTransition: PropTypes.func.isRequired,

  apiCompanySignGetAllStore: MobxPropTypes.observableObject,
  apiContentCreateStore: MobxPropTypes.observableObject,
  apiContentRenderStore: MobxPropTypes.observableObject,
  apiContentUpdateStore: MobxPropTypes.observableObject,
  apiUserGetMeStore: MobxPropTypes.observableObject,
  displayEditorStore: MobxPropTypes.observableObject,
  navigateContentStore: MobxPropTypes.observableObject,
};

EditorSide.wrappedComponent = {};
EditorSide.wrappedComponent.propTypes = {
  apiCompanySignGetAllStore: MobxPropTypes.observableObject.isRequired,
  apiContentCreateStore: MobxPropTypes.observableObject.isRequired,
  apiContentRenderStore: MobxPropTypes.observableObject.isRequired,
  apiContentUpdateStore: MobxPropTypes.observableObject.isRequired,
  apiUserGetMeStore: MobxPropTypes.observableObject.isRequired,
  displayEditorStore: MobxPropTypes.observableObject.isRequired,
  navigateContentStore: MobxPropTypes.observableObject.isRequired,
};

export default inject(EditorSide)(
  observer(EditorSide)
);
